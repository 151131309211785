import { create, Jss } from 'jss';
import preset from 'jss-preset-default';
import { Theme } from '@material-ui/core/styles';

import { theme } from '../theme';

export const createContext = (): { jss: Jss; theme: Theme } => {
    const jss = create(preset());

    return {
        jss,
        theme,
    };
};
