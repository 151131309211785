import { palette } from '../palette';

export const MuiInputBase = {
    root: {},
    input: {
        '&::placeholder': {
            opacity: 1,
            color: palette.text.secondary,
        },
    },
};
