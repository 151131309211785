export const MuiOutlinedInput = {
    root: {
        borderRadius: '.8rem',
        backgroundColor: '#000000',
    },
    notchedOutline: {
        borderColor: 'transparent!important',
    },
    input: {
        padding: '1.6rem',
        fontSize: '1.4rem',
        outlineOffset: '2px',
        color: '#FFF!important',
        outline: '2px solid transparent',
        '&::placeholder': {
            color: '#868788!important',
        },
    },
};
