import { createTheme } from '@material-ui/core';

import { palette } from './palette';
import { typography } from './typography';
import { overrides } from './overrides';
import { dimensions } from './dimensions';
import { transition } from './transitions';

const theme = createTheme({
    palette,
    // @ts-ignore
    typography,
    overrides,
    dimensions,
    transition,
});

export { theme };
