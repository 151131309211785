import { Box, styled } from '@material-ui/core';

import Logo from '../../images/logo.png';

const styles = {
    root: {
        zoom: 1,
        width: '100%',
        position: 'relative',
    },
    container: {
        display: 'flex',
        alignItems: 'center!important',
        justifyContent: 'center!important',
        minHeight: '90vh!important',
    },
    background: {
        background: '#000000',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
    boxContent: {
        position: 'relative',
    },
    contentWrap: {
        display: 'flex',
        flex: 1,
    },
    orgLogin: {
        flex: 1,
    },
};

const Section = styled('section')(() => ({
    flex: 1,
    width: '100%',
}));

const Header = styled('header')(() => ({
    background: '#000',
    color: '#fff',
    padding: '10px 0',
    textAlign: 'center',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        height: '40px',
    },
}));

const ShadowBox = styled('div')(() => ({
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',
    color: '#FFF',
    padding: '4rem!important',
    background: '#222222',
    boxShadow: '0 1px 12px rgba(0,0,0,.15)',
    borderColor: '#cbc3d3',
}));

type OnboardingProps = {
    children: JSX.Element;
};

const Page = ({ children }: OnboardingProps): JSX.Element => {
    return (
        <Box sx={styles.root}>
            <Header>
                <img src={Logo} alt="Wi-flix Logo" />
            </Header>
            <Box sx={styles.container}>
                <Box sx={styles.background} />
                <ShadowBox>
                    <Section>
                        <Box sx={styles.boxContent}>
                            <Box sx={styles.contentWrap}>{children}</Box>
                        </Box>
                    </Section>
                </ShadowBox>
            </Box>
        </Box>
    );
};

export { Page };
