import { Component, ErrorInfo, ReactNode } from 'react';
import { withStyles, ThemeProvider, StylesProvider, Theme } from '@material-ui/core/styles';
import { Ctx } from '@wi-flix/after';
import { AsyncRouteComponentType } from '@wi-flix/after/src/types';
import Typography from '@material-ui/core/Typography';

import { Error500 } from './Error500';
import { createContext } from '../../styles/createContext';

type RootState = {
    error?: Error;
};

const baseStyles = (theme: Theme) => ({
    '@global': {
        html: {
            '-webkit-text-size-adjust': '100%',
        },
        'html, body': {
            fontSize: 10,
            height: '100%',
            fontFamily:
                '"Poppins",sans-serif,Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans',
        },
        body: {
            maxWidth: '100%',
            margin: 0,
            lineHeight: 1.5,
            padding: 0,
            overflow: 'auto',
            overflowX: 'hidden!important',
        },
        'body, button': {
            WebkitFontSmoothing: 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
        },
        '*': {
            boxSizing: 'border-box',
        },
        '#root, #app': {
            display: 'table',
            tableLayout: 'fixed',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        a: {
            color: 'hsl(202,40%,55%)',
            background: 'transparent',
            textDecoration: 'none',
        },
        button: {
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
            appearance: 'none',
            background: 'none',
        },
        fieldset: {
            minWidth: 0,
            border: 0,
        },
        'fieldset, legend': {
            margin: 0,
            padding: 0,
        },
        ':focus': {
            outline: 0,
        },
        'h1, h2, h3, h4, h5, h6': {
            border: 0,
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            outline: 0,
            padding: 0,
            verticalAlign: 'baseline',
        },
        'ul, ol, p, h1, h2, h3, h4, h5, h6': {
            textRendering: 'auto',
        },
        'ul, ol': {
            padding: 0,
        },
        'p, h2, h3, h4, h5, h6': {
            wordWrap: 'break-word',
        },
        input: {
            lineHeight: 'normal',
        },
        'h1, h2, h3': {
            marginTop: 20,
            marginBottom: 10,
        },
        h3: {
            margin: '-7px 0 16px',
            bottom: -4,
            fontSize: 14,
            lineHeight: '16px',
        },
        '.atlaskit-portal': {
            zIndex: `${theme.zIndex.snackbar} !important`,
        },
    },
});

const context = createContext();
const AppWrapper = withStyles(baseStyles)(
    ({ children }: { children: JSX.Element }): JSX.Element => children,
);

function withRoot<T>(BaseComponent: AsyncRouteComponentType<T>): AsyncRouteComponentType<T> {
    class Root extends Component<T, RootState> {
        constructor(props: T) {
            super(props);
            this.state = { error: undefined };
        }

        componentDidMount(): void {
            // Remove the server-side injected CSS.
            const serverStyles = document.querySelector('#server-styles');
            if (serverStyles && serverStyles.parentNode) {
                serverStyles.parentNode.removeChild(serverStyles);
            }
        }

        componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
            // eslint-disable-next-line no-console
            console.log(errorInfo);
            this.setState({ error });
        }

        static getChunkName(): string | undefined {
            return BaseComponent.getChunkName() || 'Root';
        }

        static getInitialProps(props: Ctx<any>): any {
            return BaseComponent.getInitialProps(props);
        }

        render(): ReactNode {
            const { error } = this.state;

            return (
                <StylesProvider jss={context.jss}>
                    <ThemeProvider theme={context.theme}>
                        <AppWrapper>
                            {(() => {
                                if (error) {
                                    // TODO: do a proper error reporting page
                                    return (
                                        <Error500>
                                            <Typography variant="subtitle1">
                                                {error.message}
                                            </Typography>
                                        </Error500>
                                    );
                                }

                                // eslint-disable-next-line react/jsx-props-no-spreading
                                return <BaseComponent {...this.props} />;
                            })()}
                        </AppWrapper>
                    </ThemeProvider>
                </StylesProvider>
            );
        }
    }

    return Root;
}

export { withRoot };
