import { colors } from '@material-ui/core';

export const MuiLinearProgress = {
    root: {
        borderRadius: 3,
        overflow: 'hidden',
    },
    colorPrimary: {
        backgroundColor: colors.blueGrey[50],
    },
};
