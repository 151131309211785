import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useCallback, useEffect } from 'react';

import { useFragmentContext } from '../../../shared/helpers/useFragmentContext';
import { FragmentContext } from '../../../shared/types/fragmentContext';
import { Page } from '../../components/Page';
import { withRoot } from '../../components/Root';
import AdImage from '../../images/ad.jpeg';

const Wrapper = styled('section')(() => ({
    flex: 1,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& input': {
        width: '100%',
        height: '100%',
        maxWidth: 600,
        objectFit: 'fill',
        borderRadius: 10,
        marginBottom: 20,

        '&:hover': {
            transform: 'scale(0.98)',
        },

        '@media (min-width: 600px)': {
            maxWidth: 400,
        },
    },

    '& h4': {
        textAlign: 'center',
        margin: 0,
    },

    '& button': {
        marginTop: 20,
    },
}));

const SelectNetworkComponent = (): JSX.Element => {
    const { target } = useFragmentContext<FragmentContext>();

    const REDIRECT_TIME = 15000;

    const redirectPage = useCallback(() => {
        window.location.assign(target || 'http://onboarding.wi-flix.com');
    }, [target]);

    useEffect(() => {
        const redirectTimer = setTimeout(redirectPage, REDIRECT_TIME);
        return () => {
            clearTimeout(redirectTimer);
        };
    }, [redirectPage]);

    return (
        <Page>
            <Wrapper>
                <input
                    type="image"
                    src={AdImage}
                    alt="Wi-flix advertisement image"
                    onClick={redirectPage}
                />
                <h4>Can&apos;t continue? Disable any ad-blockers and click continue.</h4>
                <Button variant="contained" color="primary" onClick={redirectPage}>
                    Continue
                </Button>
            </Wrapper>
        </Page>
    );
};

SelectNetworkComponent.getInitialProps = () => {
    // get initial component props
};

SelectNetworkComponent.getChunkName = () => {
    return 'SelectNetwork';
};

const SelectNetwork = withRoot(SelectNetworkComponent);

export { SelectNetwork };
