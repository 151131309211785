import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '@wi-flix/after';
import TagManager from 'react-gtm-module';

import { fragmentContext } from '../shared/helpers/fragmentContext';
import { routes } from './routes';

import 'typeface-roboto';
import 'typeface-poppins';

// eslint-disable-next-line promise/catch-or-return
ensureReady(routes).then(data => {
    if (process.env.NODE_ENV === 'production') {
        TagManager.initialize({
            gtmId: 'GTM-T6V3XP8',
        });
    }

    return hydrate(
        <BrowserRouter>
            {/* @ts-ignore */}
            <fragmentContext.Provider value={data?.contextValue}>
                <After data={data} routes={routes} />
            </fragmentContext.Provider>
        </BrowserRouter>,
        document.getElementById('root'),
    );
});

if (module.hot) {
    module.hot.accept();
}
