import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export const palette = {
    black,
    white,
    primary: {
        contrastText: white,
        dark: '#d82e00',
        main: '#FF4310',
        light: '#87bcff',
    },
    secondary: {
        contrastText: white,
        dark: 'b55d00',
        main: '#ee8900',
        light: 'ffbb44',
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: '#000000',
        paper: white,
    },
    divider: colors.grey[200],
};
